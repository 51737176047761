import { CheckmarkIcon, SquareIcon, StarIcon, VideoCameraIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getFormattedDateAndTime } from '@va/util/helpers';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useMarkSeenRecording } from '../apiClient';
import { useRecordingsContext } from '../context';
import { RecordingNoteIcon } from '../RecordingNoteIcon';
import { useIsHoveringOnTableRow } from '../useIsHoveringOnTableRow';

type IdAndDateProp = {
  recordingId: string;
  seen: boolean;
  star: boolean;
  date: number;
  note?: string;
  iconColor?: string;
  iconWrapperClassName?: string;
  size?: 'small' | 'medium';
  textClassName?: string;
  secondaryTextClassName?: string;
  onTitleClick?: (recordingId: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const IdAndDateCell: React.FC<IdAndDateProp> = ({
  recordingId,
  seen,
  date,
  star,
  note,
  iconWrapperClassName = '',
  iconColor,
  size = 'medium',
  secondaryTextClassName,
  textClassName,
  onTitleClick,
}) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const cellRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const { selectRecording, recordings, isRecordingSelected, deselectRecording } = useRecordingsContext();

  const { execute: markSeen } = useMarkSeenRecording([recordingId]);

  const isHoveringOnIcon = useIsHovering(iconRef);
  const isHoveringOnTableRow = useIsHoveringOnTableRow(cellRef);

  const icon = useMemo(() => {
    if (isRecordingSelected(recordingId))
      return (
        <button
          className={classNames('w-full h-full flex items-center justify-center', {
            'bg-white': isHoveringOnTableRow,
            'bg-white-snow': !isHoveringOnTableRow,
            [iconWrapperClassName]: !isHoveringOnTableRow,
          })}
          onClick={(event) => {
            event.stopPropagation();
            deselectRecording(recordingId);
          }}
        >
          <CheckmarkIcon
            className={classNames({
              'w-4': size === 'small',
            })}
            color={iconColor}
          />
        </button>
      );

    if (isHoveringOnIcon)
      return (
        <TooltipWrapper content={translate('button.selectItem')}>
          <button
            className='w-full h-full bg-white flex items-center justify-center'
            onClick={(event) => {
              event.stopPropagation();
              selectRecording(recordings.find((recording) => recording.id === recordingId)?.id ?? '');
            }}
          >
            <SquareIcon
              className={classNames({
                'w-4': size === 'small',
              })}
              color={iconColor}
            />
          </button>
        </TooltipWrapper>
      );

    return (
      <VideoCameraIcon
        className={classNames({
          'w-4': size === 'small',
        })}
        color={iconColor}
      />
    );
  }, [
    deselectRecording,
    iconColor,
    iconWrapperClassName,
    isHoveringOnIcon,
    isHoveringOnTableRow,
    isRecordingSelected,
    recordingId,
    recordings,
    selectRecording,
    size,
    translate,
  ]);

  const paragraphPlaceholderMarginClass = useMemo(() => {
    if (star && note) return '';
    if (!star && note) return 'mr-3';
    if (!note && star) return 'mr-3';
    return 'mr-[24px]';
  }, [note, star]);

  return (
    <div ref={cellRef} className='flex items-center w-full justify-start gap-2'>
      <div
        ref={iconRef}
        className={classNames(
          'shrink-0 flex items-center justify-center overflow-hidden rounded-full group-hover:bg-white bg-white-snow',
          iconWrapperClassName,
          {
            'w-12 h-12': size === 'medium',
            'w-9 h-9': size === 'small',
          },
        )}
      >
        {icon}
      </div>
      <div
        className='text-left flex-grow overflow-hidden cursor-pointer'
        onClick={(event) => {
          if (!seen) {
            markSeen(true);
          }

          onTitleClick?.(recordingId, event);
        }}
      >
        <div className='flex items-center justify-start gap-1'>
          {star && <StarIcon color='#F66F1E' />}
          {note && <RecordingNoteIcon note={note} />}
          <TooltipWrapper content={recordingId.toUpperCase()}>
            <Paragraph
              size={size === 'medium' ? paragraphSizes.tiny : paragraphSizes.normal}
              weight={fontWeights.medium}
              className={classNames('w-full truncate', paragraphPlaceholderMarginClass, textClassName, {
                '!leading-18': size === 'small',
              })}
            >
              {recordingId.toUpperCase()}
            </Paragraph>
          </TooltipWrapper>
        </div>
        <Paragraph
          className={secondaryTextClassName}
          colorClassName='text-text-secondary'
          size={paragraphSizes.tiny}
          weight={fontWeights.medium}
        >
          {getFormattedDateAndTime(date, locale)}
        </Paragraph>
      </div>
    </div>
  );
};

export default IdAndDateCell;
