import { UPDATE_WEBSITES_EVENT, websiteFlagKeys } from '@va/constants';
import { getPrivacySettingsSucceeded, requestWebsiteSucceeded, updateWebsiteFlag } from '@va/dashboard/actions/api';
import { setInstanceId } from '@va/dashboard/actions/app';
import { useAddNewWebsite } from '@va/dashboard/api-client/Website';
import { buildDataPrivacyPayload, useUpdatePrivacySettings } from '@va/dashboard/feature/privacy-center';
import { useTranslate } from '@va/localization';
import { mutateWebsites, setActiveWebsite } from '@va/standalone/shared/actions';
import { storageItems } from '@va/standalone/shared/constants';
import { DottedLoadingIndicator, Heading3, Paragraph } from '@va/ui/design-system';
import { LocalStorage } from '@va/util/helpers';
import { EventBus } from '@va/util/misc';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AddWebsiteValues } from './useAddWebsiteSteps';

type LoadingStepProps = {
  onSuccess: () => void;
  values: AddWebsiteValues;
};

export const LoadingStep = ({ onSuccess, values }: LoadingStepProps) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const { execute: updatePrivacyLevel } = useUpdatePrivacySettings();
  const { execute: addNewWebsite, isSucceeded: isAddNewWebsiteSucceeded } = useAddNewWebsite();

  useEffect(() => {
    (async () => {
      if (!values.websiteUrl) return;

      const newWebsite = await addNewWebsite({ url: values.websiteUrl, platform: 'CUSTOM', source: 'dashboard' });
      dispatch(setInstanceId(newWebsite.id));
      dispatch(setActiveWebsite(newWebsite.id));
      dispatch(requestWebsiteSucceeded(newWebsite));
      dispatch(updateWebsiteFlag(websiteFlagKeys.CREATION_PROCESS_COMPLETED, false));
      LocalStorage.setItem(storageItems.lastWebsiteId, newWebsite.id);
      EventBus.dispatch(UPDATE_WEBSITES_EVENT);
      dispatch(mutateWebsites());
    })();
  }, [addNewWebsite, dispatch, values.websiteUrl]);

  useEffect(() => {
    (async () => {
      if (!isAddNewWebsiteSucceeded || values.privacyMode === undefined) return;

      const privacySettingsData = await updatePrivacyLevel(buildDataPrivacyPayload(values.privacyMode));
      dispatch(getPrivacySettingsSucceeded({ data: privacySettingsData?.payload }));
      onSuccess();
    })();
  }, [dispatch, isAddNewWebsiteSucceeded, onSuccess, updatePrivacyLevel, values.privacyMode]);

  return (
    <div className='flex flex-col justify-center items-center h-400px'>
      <DottedLoadingIndicator className='mb-30px' />
      <Heading3>{translate('onboarding.loading.title')}</Heading3>
      <Paragraph>{translate('onboarding.loading.subtitle')}</Paragraph>
    </div>
  );
};
