import { useTranslate } from '@va/localization';
import { Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { formatSessionDuration } from '@va/util/helpers';
import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { getEventIcon } from '../helpers';
import ProgressBarEvent from './ProgressBarEvent';

type ProgressBarTooltipProps =
  | {
      totalTime?: never;
      eventData: ProgressBarEvent;
    }
  | {
      totalTime: number;
      eventData?: never;
    };

export const ProgressBarTooltip = ({ eventData, totalTime, children }: PropsWithChildren<ProgressBarTooltipProps>) => {
  const translate = useTranslate();
  const tooltipTriggerRef = useRef<HTMLDivElement>(null);
  const playerTimeOnHover = useRef<number | null>(null);
  const fromTimestamp = eventData ? formatSessionDuration(eventData.delay) : undefined;
  const toTimestamp = eventData ? eventData.to && formatSessionDuration(eventData.to) : undefined;

  useEffect(() => {
    if (!tooltipTriggerRef.current || eventData) return () => {};
    const tooltipTrigger = tooltipTriggerRef.current;

    const mouseMoveHandler = (event: MouseEvent) => {
      const rect = tooltipTrigger.getBoundingClientRect();
      const x = event.clientX - rect.left; //x position within the element.

      playerTimeOnHover.current = Math.round((x / rect.width) * totalTime);
    };

    tooltipTrigger.addEventListener('mousemove', mouseMoveHandler);

    return () => {
      tooltipTrigger?.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [eventData, totalTime]);

  const tooltipContent = useMemo(() => {
    if (!eventData) {
      return formatSessionDuration(playerTimeOnHover.current ?? 0);
    }
    return toTimestamp ? fromTimestamp + ' - ' + toTimestamp : fromTimestamp;
  }, [eventData, fromTimestamp, toTimestamp]);

  return (
    <TooltipWrapper
      tooltipClassNames={'rounded-10.5 px-18px py-10.5 bg-black-lighter-84'}
      useDefaultStyle={false}
      followCursorAxis='x'
      ref={tooltipTriggerRef}
      content={
        <div className={'flex-col text-center'}>
          <Paragraph colorClassName={'text-white'} size={paragraphSizes.tiny}>
            {tooltipContent}
          </Paragraph>
          <div style={{ color: eventData?.color ?? 'white' }} className={'flex flex-row items-center gap-1'}>
            {eventData ? (
              <>
                <div>
                  {getEventIcon({
                    eventDetails: eventData.name,
                    color: eventData.color,
                  }).icon()}
                </div>
                {eventData.details}
              </>
            ) : (
              translate('ssrPlayer.progressBarTooltip.noVisitorAction')
            )}
          </div>
        </div>
      }
      placement={'top'}
    >
      {children}
    </TooltipWrapper>
  );
};
