import { EventType, IncrementalSource, MouseInteractions } from '@visa/rrweb';
import { AlarmingEventTrigger, AlarmingEventType, EventTypeEnum, MappedEventType } from '@va/types/recordings';
import { isAbesFeatureEnabled } from '@va/util/helpers';
import {
  AbeIcon,
  BorderIcon,
  ClickIcon,
  FallbackIconCircle,
  MouseScrollIcon,
  PendingIcon,
  ProcessIcon,
  TapIcon,
} from '@va/icons';

export function onFullscreenChange(handler: () => void) {
  document.addEventListener('fullscreenchange', handler);
  document.addEventListener('webkitfullscreenchange', handler);
  document.addEventListener('mozfullscreenchange', handler);
  document.addEventListener('MSFullscreenChange', handler);

  return () => {
    document.removeEventListener('fullscreenchange', handler);
    document.removeEventListener('webkitfullscreenchange', handler);
    document.removeEventListener('mozfullscreenchange', handler);
    document.removeEventListener('MSFullscreenChange', handler);
  };
}

export function onWindowResize(handler: () => void) {
  window.addEventListener('resize', handler);
  return () => {
    window.removeEventListener('resize', handler);
  };
}

export function onMouseLeavePlayer(handler: () => void) {
  const player = document.getElementById('ssr-player');

  if (!player) return;

  player.addEventListener('mouseleave', handler);

  return () => {
    player.removeEventListener('mouseleave', handler);
  };
}

export function onMouseMovePlayer(handler: () => void) {
  const player = document.getElementById('ssr-player');

  if (!player) return;

  player.addEventListener('mousemove', handler);
  return () => {
    player.removeEventListener('mousemove', handler);
  };
}

export function isFullscreen() {
  // @ts-ignore
  return document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement;
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.mozExitFullscreen) {
    /* Firefox */
    return document.mozExitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    return document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    return document.msExitFullscreen();
  }
}

export function openFullscreen(el: Element) {
  if (el.requestFullscreen) {
    return el.requestFullscreen();
  } else if (el.mozRequestFullscreen) {
    /* Firefox */
    return el.mozRequestFullscreen();
  } else if (el.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    return el.webkitRequestFullscreen();
  } else if (el.msRequestFullscreen) {
    /* IE/Edge */
    return el.msRequestFullscreen();
  }
}

type SSREvent = {
  type: EventType;
  data: {
    href?: string;
    source?: number | string;
    type?: number;
  };
};

export function getEventIcon({ eventDetails, color }: { eventDetails: string; color?: string }): {
  backgroundColor?: string;
  icon: (className?: string) => JSX.Element;
} {
  switch (eventDetails) {
    case EventTypeEnum.mouseDown:
    case EventTypeEnum.mouseUp:
    case EventTypeEnum.click:
    case EventTypeEnum.dblClick:
      return { icon: (className) => <ClickIcon color={color ?? '#0C635A'} className={className} /> };
    case EventTypeEnum.touchStart:
    case EventTypeEnum.touchEnd:
      return { icon: (className) => <TapIcon color={color ?? '#0C635A'} className={className} /> };
    case EventTypeEnum.scroll:
      return { icon: (className) => <MouseScrollIcon color={color ?? '#F66F1E'} className={className} /> };
    case EventTypeEnum.navigateToOtherPage:
      return { icon: (className) => <ProcessIcon color={color ?? '#A50FEC'} className={className} /> };
    case EventTypeEnum.focus:
      return { icon: (className) => <PendingIcon color={color ?? '#2478F0'} className={className} /> };
    case EventTypeEnum.viewportResize:
      return { icon: (className) => <BorderIcon color={color ?? '#ED3FA5'} className={className} /> };
    case EventTypeEnum.rageClick:
    case EventTypeEnum.rageClickStart:
    case EventTypeEnum.rageClickEnd:
    case EventTypeEnum.mouseMovesStart:
    case EventTypeEnum.mouseMovesEnd:
    case EventTypeEnum.mouseMoveAbe:
    case EventTypeEnum.deadClick:
    case EventTypeEnum.scrollStart:
    case EventTypeEnum.scrollEnd:
    case EventTypeEnum.uTurn:
    case EventTypeEnum.refresh:
      return {
        backgroundColor: '#EA2A0C',
        icon: (className) => <AbeIcon color={color ?? '#fff'} className={className} />,
      };

    case EventTypeEnum.input: // TODO Add a new icon for this
    default:
      return { icon: (className) => <FallbackIconCircle className={className} /> };
  }
}

export function computeEventDetails(event: SSREvent) {
  switch (event.type) {
    case EventType.Meta:
      return {
        color: '#4A90E2',
        details: `Navigate to ${event.data.href}`,
        name: 'meta',
        extras: { url: event.data.href },
      };
    case EventType.FullSnapshot:
      return {
        color: '#F492F7',
        details: 'Full Snapshot',
        name: 'fullSnapshot',
      };
    case EventType.IncrementalSnapshot: {
      switch (event.data.source) {
        case IncrementalSource.MouseMove:
          return {
            color: '#3A505A',
            details: 'Mouse moved',
            name: 'mouseMove',
          };
        case IncrementalSource.Input:
          return {
            color: '#F6EC67',
            details: 'Input field change',
            name: 'input',
          };
        case IncrementalSource.Mutation:
          return { color: '#4A90E2', details: 'Mutation', name: 'mutation' };
        case IncrementalSource.Scroll:
          return { color: '#016D62', details: 'Scrolling', name: 'scroll' };
        case IncrementalSource.ViewportResize:
          return {
            color: '#73DEF6',
            details: 'Viewport resize',
            name: 'viewportResize',
          };
        case IncrementalSource.MouseInteraction: {
          switch (event.data.type) {
            case MouseInteractions.MouseUp:
              return { color: '#FDC96B', details: 'Mouse up', name: 'mouseUp' };
            case MouseInteractions.MouseDown:
              return {
                color: '#F2B44F',
                details: 'Mouse down',
                name: 'mouseDown',
              };
            case MouseInteractions.Click:
              return { color: '#FF5252', details: 'Click', name: 'click' };
            case MouseInteractions.ContextMenu:
              return {
                color: '#455773',
                details: 'Context menu',
                name: 'contextMenu',
              };
            case MouseInteractions.DblClick:
              return {
                color: '#C23853',
                details: 'Double click',
                name: 'dblClick',
              };
            case MouseInteractions.Focus:
              return { color: '#F27F4F', details: 'Focus', name: 'focus' };
            case MouseInteractions.Blur:
              return { color: '#49C5B6', details: 'Blur', name: 'blur' };
            case MouseInteractions.TouchStart:
              return {
                color: '#F492F7',
                details: 'Touch start',
                name: 'touchStart',
              };
            case MouseInteractions.TouchMove_Departed:
              return {
                color: '#8F7CED',
                details: 'Touch move',
                name: 'touchMove',
              };
            case MouseInteractions.TouchEnd:
              return {
                color: '#8F7CED',
                details: 'Touch end',
                name: 'touchEnd',
              };
            default:
              return {
                color: '#FDC96B',
                details: `${MouseInteractions[event.data.type as unknown as keyof typeof MouseInteractions]}`,
              };
          }
        }
        default:
          return {
            color: '#55BFA0',
            details: `${IncrementalSource[event.data.source as keyof typeof IncrementalSource]}`,
          };
      }
    }
    case EventType.Custom: {
      switch (event.data.source) {
        case AlarmingEventTrigger.rageClicks: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name:
              event.data.type === AlarmingEventType.ABE_START
                ? EventTypeEnum.rageClickStart
                : EventTypeEnum.rageClickEnd,
          };
        }
        case AlarmingEventTrigger.deadClicks: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: EventTypeEnum.deadClick,
          };
        }
        case AlarmingEventTrigger.scrolls: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: event.data.type === AlarmingEventType.ABE_START ? EventTypeEnum.scrollStart : EventTypeEnum.scrollEnd,
          };
        }
        case AlarmingEventTrigger.mouseMoves: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name:
              event.data.type === AlarmingEventType.ABE_START
                ? EventTypeEnum.mouseMovesStart
                : EventTypeEnum.mouseMovesEnd,
          };
        }
        case AlarmingEventTrigger.uTurns: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: EventTypeEnum.uTurn,
          };
        }
        case AlarmingEventTrigger.refreshes: {
          return {
            color: '#FF0000',
            details: event.data.type,
            name: EventTypeEnum.refresh,
          };
        }

        default: {
          return { color: '#FDC96B', details: event.data.source };
        }
      }
    }

    default:
      return { color: '#FDC96B', details: `${EventType[event.type]}` };
  }
}

export const isEventNeeded = (event: SSREvent) => {
  switch (event.type) {
    case EventType.Meta:
      return true;
    case EventType.IncrementalSnapshot: {
      switch (event.data.source) {
        case IncrementalSource.Input:
        case IncrementalSource.Scroll:
        case IncrementalSource.ViewportResize:
        case IncrementalSource.MouseMove:
          return true;
        case IncrementalSource.MouseInteraction: {
          switch (event.data.type) {
            case MouseInteractions.Click:
            case MouseInteractions.MouseDown:
            case MouseInteractions.MouseUp:
            case MouseInteractions.DblClick:
            case MouseInteractions.Focus:
            case MouseInteractions.TouchStart:
            case MouseInteractions.TouchEnd:
              return true;
            default:
              return false;
          }
        }
        default:
          return false;
      }
    }
    case EventType.Custom: {
      switch (event.data.source) {
        case AlarmingEventTrigger.rageClicks:
        case AlarmingEventTrigger.deadClicks:
        case AlarmingEventTrigger.scrolls:
        case AlarmingEventTrigger.mouseMoves:
        case AlarmingEventTrigger.refreshes:
        case AlarmingEventTrigger.uTurns:
          return isAbesFeatureEnabled();
        default:
          return false;
      }
    }
    default:
      return false;
  }
};

export const scrollToActiveElement = (element: Element, wrapperRef: HTMLElement) => {
  const elementOffsetTop = wrapperRef.scrollTop + element.getBoundingClientRect().top;
  // Half of wrapper height to position element in center
  wrapperRef.scrollTop = elementOffsetTop - wrapperRef.offsetHeight / 2;
};

export const EVENTS_COMPACT_CONFIGURATION = [
  {
    startEvent: EventTypeEnum.rageClickStart,
    endEvent: EventTypeEnum.rageClickEnd,
    namesToRemove: [
      EventTypeEnum.click,
      EventTypeEnum.dblClick,
      EventTypeEnum.mouseDown,
      EventTypeEnum.mouseUp,
      EventTypeEnum.focus,
      EventTypeEnum.deadClick,
    ],
  },
  {
    startEvent: EventTypeEnum.scrollStart,
    endEvent: EventTypeEnum.scrollEnd,
    namesToRemove: [EventTypeEnum.scroll],
  },
  {
    startEvent: EventTypeEnum.mouseMovesStart,
    endEvent: EventTypeEnum.mouseMovesEnd,
    namesToRemove: [EventTypeEnum.mouseMove],
  },
];

export function compactRecordingEvents(
  events: Array<MappedEventType>,
  compactionRules: Array<{
    startEvent: EventTypeEnum;
    endEvent: EventTypeEnum;
    namesToRemove: Array<EventTypeEnum>;
  }>,
) {
  const activeRanges = new Set<EventTypeEnum>();

  return events.filter((event) => {
    const rangeBoundary = compactionRules.find(
      (rule) =>
        (event.type === EventType.Custom && event.name === rule.startEvent) ||
        (event.type === EventType.Custom && event.name === rule.endEvent),
    );

    if (rangeBoundary) {
      if (event.name === rangeBoundary.startEvent) {
        activeRanges.add(rangeBoundary.startEvent);
      } else if (event.name === rangeBoundary.endEvent) {
        activeRanges.delete(rangeBoundary.startEvent);
      }
      return true; // Keep start and end events
    }

    for (const rule of compactionRules) {
      if (activeRanges.has(rule.startEvent) && rule.namesToRemove.includes(event.name as EventTypeEnum)) {
        return false; // Filter out event
      }
    }

    return true; // Keep the event if no rule matches
  });
}

export function checkCondition(a: MappedEventType, b: MappedEventType) {
  const conditionsMap: Partial<{
    [key in EventTypeEnum]: {
      eventType: EventTypeEnum;
      eventTypeAfterMerge: EventTypeEnum;
      maxDelayDifference?: number;
    };
  }> = {
    // Regular events
    [EventTypeEnum.viewportResize]: {
      eventType: EventTypeEnum.viewportResize,
      maxDelayDifference: 1000,
      eventTypeAfterMerge: EventTypeEnum.viewportResize,
    },
    [EventTypeEnum.mouseMove]: {
      eventType: EventTypeEnum.mouseMove,
      maxDelayDifference: 1000,
      eventTypeAfterMerge: EventTypeEnum.mouseMove,
    },
    [EventTypeEnum.input]: {
      eventType: EventTypeEnum.input,
      maxDelayDifference: 1000,
      eventTypeAfterMerge: EventTypeEnum.input,
    },
    [EventTypeEnum.scroll]: {
      eventType: EventTypeEnum.scroll,
      maxDelayDifference: 1000,
      eventTypeAfterMerge: EventTypeEnum.scroll,
    },
    // ABEs
    [EventTypeEnum.rageClickStart]: {
      eventType: EventTypeEnum.rageClickEnd,
      eventTypeAfterMerge: EventTypeEnum.rageClick,
    },
    [EventTypeEnum.mouseMovesStart]: {
      eventType: EventTypeEnum.mouseMovesEnd,
      eventTypeAfterMerge: EventTypeEnum.mouseMoveAbe,
    },
    [EventTypeEnum.scrollStart]: { eventType: EventTypeEnum.scrollEnd, eventTypeAfterMerge: EventTypeEnum.scrollAbe },
  };

  const timeBetweenEvents = b.delay - a.delay;

  if (!conditionsMap[a.name]) return { merge: false, eventTypeAfterMerge: undefined };

  if (conditionsMap[a.name]?.maxDelayDifference) {
    return {
      merge:
        conditionsMap[a.name]?.eventType === b.name && timeBetweenEvents <= conditionsMap[a.name]!.maxDelayDifference!,
      eventTypeAfterMerge: conditionsMap[a.name]?.eventTypeAfterMerge,
    };
  }

  return {
    merge: conditionsMap[a.name]?.eventType === b.name,
    eventTypeAfterMerge: conditionsMap[a.name]?.eventTypeAfterMerge,
  };
}

export function mergeEvents(events: MappedEventType[]) {
  if (events.length === 0) return events;

  const mergedEvents = [];

  let currentEvent = events[0];
  for (let i = 1; i < events.length; i++) {
    const { merge, eventTypeAfterMerge } = checkCondition(currentEvent, events[i]);
    if (merge) {
      currentEvent = {
        ...currentEvent,
        to: events[i].delay,
        name: eventTypeAfterMerge as EventTypeEnum,
      };
    } else {
      mergedEvents.push(currentEvent);
      currentEvent = events[i];
    }
  }

  mergedEvents.push(currentEvent);

  return mergedEvents;
}
