import { userActions } from '@va/constants';
import {
  CheckmarkIcon,
  EmptyCircleIcon,
  EmptyStarIcon,
  LinkIcon,
  MinusIcon,
  MinusStarIcon,
  MoreOptionsIcon,
  NotesIcon,
} from '@va/icons';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { hasPermission as hasPermissionSelector } from '@va/standalone/shared/selectors';
import { ButtonColors, SelectActionDropdown, SelectActionDropdownOption } from '@va/ui/design-system';

import { useAddNotification } from '@va/dashboard/util-hooks';
import { RecordingsDataType } from '@va/types/recordings';
import { is3asApp, isWixApp, isWixMiniApp } from '@va/util/helpers';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetRecordingLink, useMarkSeenRecording, useStarRecordings } from './apiClient';
import { DeleteSingleRecordingConfirmationDialog } from './DeleteSingleRecordingConfirmationDialog';
import { useRecordingsContext } from './context';

type RecordingsOperationsButtonProps = {
  recordingId: string;
  star: boolean;
  seen: boolean;
  hasComment: boolean;
  buttonColor?: ButtonColors;
  className?: string;
};

export const RecordingsOperationsButton: React.FC<RecordingsOperationsButtonProps> = ({
  recordingId,
  star,
  seen,
  hasComment,
  buttonColor = 'tertiary',
  className,
}) => {
  const { showErrorNotification } = useAddNotification();
  const { execute: starRecording } = useStarRecordings([recordingId]);
  const { execute: markSeenRecording } = useMarkSeenRecording([recordingId]);
  const { copyLink, success: copyLinkSuccess } = useGetRecordingLink(recordingId);
  const { openAddNoteModal, deleteIndividual, mutateRecordings, recordings } = useRecordingsContext();
  const translate = useTranslate();

  const hasPermissionToEdit = useSelector((state) => hasPermissionSelector(state, userActions.Delete));
  const hasPermissionToDelete = useSelector((state) => hasPermissionSelector(state, userActions.Edit));

  // Hidden due to VISA-10998 and VISA-11232
  const isCopyURLToClipboardHidden = isWixMiniApp() || is3asApp() || isWixApp();

  const iconColor = useMemo(() => (hasPermissionToEdit ? '#999999' : '#696969'), [hasPermissionToEdit]);

  const { confirm } = useConfirmationDialogContext();

  const updateSessionExtrasData = useCallback(
    async (apiCall: (value: boolean) => Promise<any>, key: 'star' | 'seen', value: boolean) => {
      try {
        await mutateRecordings(() => apiCall(value), {
          rollbackOnError: true,
          populateCache: false,
          optimisticData: (currentData) => {
            return {
              sessionsTotal: currentData?.sessionsTotal,
              data: recordings.map((item) => {
                if (item.id === recordingId) {
                  return { ...item, sessionExtras: { ...item.sessionExtras, [key]: value } };
                }
                return item;
              }),
            } as RecordingsDataType;
          },
          revalidate: true,
        });
      } catch (err) {
        showErrorNotification();
      }
    },
    [mutateRecordings, recordingId, recordings, showErrorNotification],
  );

  const handleStarRecording = useCallback(
    (value: boolean) => {
      updateSessionExtrasData(starRecording, 'star', value);
    },
    [starRecording, updateSessionExtrasData],
  );

  const handleSeenRecording = useCallback(
    (value: boolean) => {
      updateSessionExtrasData(markSeenRecording, 'seen', value);
    },
    [markSeenRecording, updateSessionExtrasData],
  );

  const actionOptions: SelectActionDropdownOption[] = useMemo(() => {
    const actions: SelectActionDropdownOption[] = [
      {
        icon: () => (star ? <MinusStarIcon color={iconColor} /> : <EmptyStarIcon color={iconColor} />),
        text: star ? translate('recordings.actions.unstar') : translate('recordings.actions.star'),
        onClick: () => handleStarRecording(!star),
        tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
        disabled: !hasPermissionToEdit,
      },
      {
        icon: () => <NotesIcon color={iconColor} />,
        text: hasComment ? translate('recordings.actions.editNote') : translate('recordings.actions.addNote'),
        onClick: () => {
          openAddNoteModal(recordingId);
        },
        tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
        disabled: !hasPermissionToEdit,
      },
      {
        icon: () => (seen ? <EmptyCircleIcon color={iconColor} /> : <CheckmarkIcon color={iconColor} />),
        text: seen ? translate('recordings.actions.markUnseen') : translate('recordings.actions.markSeen'),
        onClick: () => handleSeenRecording(!seen),
        tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
        disabled: !hasPermissionToEdit,
      },
      {
        icon: () => <LinkIcon />,
        text: translate('button.copyRecordingLink'),
        onClick: copyLink,
        hideDropdownOnClick: false,
        tooltip: translate('button.copiedToClipboardText'),
        tooltipProps: {
          visible: copyLinkSuccess,
        },
        hidden: isCopyURLToClipboardHidden,
      },
    ];

    // VISA-11435
    if (!isWixMiniApp()) {
      actions.push({
        icon: () => <MinusIcon color={!hasPermissionToDelete ? '#696969' : '#EA2A0C'} />,
        text: translate('recordings.actions.delete'),
        textColorClass: 'text-red-pure',
        onClick: () => {
          confirm(() => {
            deleteIndividual([recordingId]);
          }, DeleteSingleRecordingConfirmationDialog);
        },
        tooltip: !hasPermissionToDelete ? translate('tooltips.contributorRole') : undefined,
        disabled: !hasPermissionToDelete,
      });
    }

    return actions;
  }, [
    star,
    translate,
    hasPermissionToEdit,
    hasComment,
    seen,
    copyLink,
    copyLinkSuccess,
    isCopyURLToClipboardHidden,
    hasPermissionToDelete,
    iconColor,
    handleStarRecording,
    openAddNoteModal,
    recordingId,
    handleSeenRecording,
    confirm,
    deleteIndividual,
  ]);

  return (
    <SelectActionDropdown
      buttonColor={buttonColor}
      wrapperClassName={classNames('group', className)}
      icon={(_, color) => <MoreOptionsIcon color={color} />}
      showRightSideIcon={false}
      options={actionOptions}
      tooltipOptions={{ placement: 'left-start', zIndex: 10 }}
    />
  );
};
