import { GlobalTimePeriodFilter } from '@va/dashboard/components';
import { useLocalizationContext } from '@va/localization';
import { ChartContext } from '@va/types/charts';
import { BaseTimeseriesResponse } from '@va/types/time-series';
import MetricSelector from '@va/ui/components/metricSelector/MetricSelector';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { externalChartTooltip, fontWeights, LineChart, Paragraph } from '@va/ui/design-system';
import { chartHelpers, formatLineDiagramDateLabels } from '@va/util/helpers';
import { ChartData } from 'chart.js';
import { useCallback, useMemo, useState } from 'react';
import { useAlarmingBehaviorEventsContext } from '../../context/ABEContext';
import { metricsDatasetLabel, metricsList, metricTranslations } from '../../data/metrics';
import { valueMapperBasedOnMetric } from '../../helpers/donutChartHelper';
import DataFetcherComponent from './ABEGraphDataFetcher';

export const AlarmingBehaviorEventsLineChart = ({ noDataAvailableKey }: { noDataAvailableKey: string }) => {
  const { translate, locale } = useLocalizationContext();
  const { id } = useReportBlockCtx();
  const { selectedAlarmingBehaviorEvents, selectedMetric, selectMetric, groupingKey } =
    useAlarmingBehaviorEventsContext();
  const [eventsData, setEventsData] = useState<
    Record<string, { data: BaseTimeseriesResponse<string> | undefined; isLoading: boolean }>
  >({});

  const isLoading = useMemo(
    () => selectedAlarmingBehaviorEvents.some((event) => !eventsData[event[groupingKey]]),
    [eventsData, groupingKey, selectedAlarmingBehaviorEvents],
  );
  const firstEventData = useMemo(
    () =>
      selectedAlarmingBehaviorEvents.length > 0
        ? eventsData[selectedAlarmingBehaviorEvents[0][groupingKey]]
        : undefined,
    [eventsData, groupingKey, selectedAlarmingBehaviorEvents],
  );

  const chartData = useMemo(() => {
    const labels = firstEventData?.data?.chartLabels
      ? formatLineDiagramDateLabels(firstEventData?.data?.chartLabels, locale)
      : [];
    const datasets = selectedAlarmingBehaviorEvents
      .map((event) => {
        const dataset = eventsData[event[groupingKey]]?.data?.items[metricsDatasetLabel[selectedMetric]];

        return (
          dataset &&
          chartHelpers.buildChartDataset({
            dataset,
            hiddenDatasets: [],
            isCurrent: true,
            isPreviousEnabled: false,
            getDatasetColor: () => event.color,
            getDatasetLabel: (dataset) => dataset.label,
          })
        );
      })
      .filter(Boolean) as ChartData<'line'>['datasets'];

    return { datasets, labels };
  }, [
    eventsData,
    firstEventData?.data?.chartLabels,
    groupingKey,
    locale,
    selectedAlarmingBehaviorEvents,
    selectedMetric,
  ]);

  const tooltipFn = useCallback(
    (ctx: ChartContext) => {
      return externalChartTooltip(ctx, {
        currentTimeIntervals: firstEventData?.data?.timeIntervals ?? [],
        previousTimeIntervals: [],
        showPrevious: false,
        title: translate('all.eventTracking.alarmingBehaviorEvents.tooltipTitle'),
        subtitle: translate('all.eventTracking.alarmingBehaviorEvents.tooltipSubtitle'),
        valueTransformer: (val) => valueMapperBasedOnMetric(val, selectedMetric, locale),
      });
    },
    [firstEventData?.data?.timeIntervals, locale, selectedMetric, translate],
  );

  const handleDataChange = useCallback(
    (data: BaseTimeseriesResponse<string> | undefined, isLoading: boolean, selectedEvent: string) => {
      setEventsData((prev) => ({
        ...prev,
        [selectedEvent]: { data, isLoading },
      }));
    },
    [],
  );

  return (
    <div className='w-full space-y-3'>
      {selectedAlarmingBehaviorEvents.map((selectedEvent, index) => {
        return (
          <DataFetcherComponent
            key={selectedEvent.trigger + index}
            selectedMetric={selectedMetric}
            onChange={handleDataChange}
            value={selectedEvent[groupingKey]}
            groupingKey={groupingKey}
          />
        );
      })}
      <div className='flex gap-3 flex-wrap'>
        <div className='flex-1'>
          <MetricSelector
            isIconVisible={false}
            translationsMap={metricTranslations}
            selectedMetric={selectedMetric}
            isDisabled={selectedAlarmingBehaviorEvents.length === 0}
            selectMetric={selectMetric}
            metricsList={metricsList}
          />
        </div>
        <div className='flex-1 flex items-center'>
          <GlobalTimePeriodFilter />
        </div>
      </div>
      {selectedAlarmingBehaviorEvents.length === 0 ? (
        <div className='h-500 flex items-center justify-center'>
          <Paragraph weight={fontWeights.medium}>{translate(noDataAvailableKey)}</Paragraph>
        </div>
      ) : (
        <LineChart
          tooltipFn={tooltipFn}
          isLoading={isLoading}
          className='w-full'
          wrapperStyle={{ height: '500px', overflow: 'hidden', width: '100%' }}
          id={`line-diagram-${id}`}
          chartData={chartData}
        />
      )}
    </div>
  );
};
