import {
  defaultTimezone,
  defaultWebsiteId,
  GDPR_RELEASE_DATE_UNIX_TS,
  platform,
  SSR_V0_RELEASE_DATE_UNIX_TS,
  urlViews,
  websiteCurrencies,
  websiteFlagKeys,
  websiteStatus,
} from '@va/constants';
import { WIX_EMBEDDED_SCRIPT_UNIX } from '@va/wix/shared/constants';
import md5 from 'blueimp-md5';
import Config from 'Config';
import Immutable from 'immutable';
import moment from 'moment';
import { createSelector } from 'reselect';

export * from './cards';
export * from './visitors';

export const getFormState = (state) => state.getIn(['core', 'form']);
export const getFormValue = (state, formName, valueName) =>
  state.getIn(['core', 'form', formName, 'values', valueName]);

export const isPremium = (state) => {
  const status = state.getIn(['core', 'website', 'status']);
  // Force websites with no platform selected to be 'premium' in order to not show upgrade buttons/
  if (status === websiteStatus.PENDING) return true;

  const isPayable = state.getIn(['core', 'website', 'isPayable'], false);
  return !!isPayable;
};

export const hasWebsite = (state) => state.getIn(['core', 'website', 'exists'], false);

export const newGDPRUser = createSelector(
  [(state) => state.getIn(['core', 'website', 'installDate'], 0)],
  (installDate) => {
    if (installDate === 0) return true;

    return installDate > GDPR_RELEASE_DATE_UNIX_TS;
  },
);

export const getSsrSettingsSelector = createSelector(
  [(state) => state.getIn(['core', 'api', 'getSsrSettings'], Immutable.Map()).toJS()],
  (ssrSettings) => ssrSettings,
);

export const getWebsite = createSelector(
  [
    (state) => state.getIn(['core', 'website'], Immutable.Map()).toJS(),
    (state) => state.getIn(['core', 'app', 'instanceId'], defaultWebsiteId),
  ],
  (website, websiteId) => {
    return Object.assign({}, website, {
      id: website.id || websiteId,
      isPremium: !!website?.isPayable,
      installDate: parseInt(website.installDate),
      hash: md5(website.id || websiteId),
    });
  },
);

export const getBusinessSector = createSelector([getWebsite], (website) => website.businessSector);
export const getFirstWeekDay = createSelector([getWebsite], (website) => website.firstWeekDay);
export const getEmail = createSelector([getWebsite], (website) => website.email);
export const getWebsiteMigrationStatus = createSelector([getWebsite], (website) => website.migrationStatus);
export const getWebsiteStatus = createSelector([getWebsite], (website) => website.status);
export const getWebsitePlatform = createSelector([getWebsite], (website) => website.platform);
export const getWebsiteCurrency = createSelector([getWebsite], (website) => website.currency || 'EUR');
export const getWebsiteCurrencySymbol = createSelector([getWebsiteCurrency], (currency) => {
  const currencyItem = websiteCurrencies.find((item) => item.code === currency);
  if (!currencyItem) return '€';
  if (currencyItem.symbol) return currencyItem.symbol;
  return currencyItem.code;
});
export const getWebsiteInstallDate = createSelector([getWebsite], (website) => moment(website.installDate * 1000));
export const getWebsiteLastInstallDate = createSelector([getWebsite, getWebsiteInstallDate], (website, installDate) => {
  // In case of a reinstall, this gets updated so that installDate can remain unchanged, as there are other validations
  if (!website?.lastInstallDate) {
    return installDate;
  }
  return moment(website.lastInstallDate * 1000);
});
export const isDataResetRequested = createSelector([getWebsite], (website) => website.resetDataRequested);
export const getWebsiteFrontendFlags = createSelector([getWebsite], (website) => website?.frontendFlags ?? {});

export const isWebsiteOnboardingCompleted = createSelector(
  [getWebsite],
  (website) => !!website?.frontendFlags?.[websiteFlagKeys.ONBOARDING_COMPLETED],
);

export const isWhiteLabelEnabled = createSelector([getWebsite], (website) => website.whiteLabel);
export const isNewWebsite = createSelector([getWebsite], (website) =>
  moment(website.installDate * 1000).isAfter(Config.clickPathDeprecatedReleaseDate * 1000),
);

export const isWixWebsite = createSelector([getWebsitePlatform], (websitePlatform) => websitePlatform === platform.WIX);
export const isFreeWixWebsite = createSelector(
  [getWebsite],
  (website) => website?.platform === platform.WIX && !website.isPremium,
);

export const isWebsiteInProgress = createSelector([getWebsite], (website) => website?.inProgress);

export const websiteReviewStatus = createSelector([getWebsite], (website) => website.reviewStatus);

export const websiteIsSumoActive = createSelector(
  [(state) => state.getIn(['core', 'website'], Immutable.Map())],
  (website) => {
    return website.get('sumoActive', false);
  },
);

export const getWebsiteSumoLimit = createSelector(
  [(state) => state.getIn(['core', 'website'], Immutable.Map())],
  (website) => {
    return website.get('sumoLimit', null);
  },
);

export const getWebsiteMonthlyVisits = createSelector(
  [(state) => state.getIn(['core', 'website'], Immutable.Map())],
  (website) => {
    return website.get('numberOfMonthlyVisits', null);
  },
);

export const getUrlViewFlag = (state) => state.getIn(['core', 'website', 'frontendFlags', 'urlView'], urlViews.TITLE);

export const hasData = createSelector(
  [(state) => state.getIn(['core', 'website'], Immutable.Map()).toJS()],
  (website) => {
    if (!website.lastVisitTs) return false;

    if (website.lastResetDate) {
      return website.lastVisitTs > website.lastResetDate;
    }

    return true;
  },
);

export const hasFirstLogin = (state) => state.getIn(['core', 'website', 'hasFirstLogin'], false);

export const getWebsiteTimezone = createSelector(
  [(state) => state.getIn(['core', 'website', 'timezone'], defaultTimezone)],
  (timezone) => timezone,
);

export const getWebsiteUrl = createSelector(
  [
    (state) => state.getIn(['core', 'api', 'settings', 'url']),
    (state) => state.getIn(['core', 'website', 'url']),
    (state) => state.getIn(['core', 'app', 'user', 'siteUrl'], '#'),
  ],
  (urlFromSettings, urlFromWebsite, userUrl) => {
    if (urlFromSettings && urlFromSettings !== 'null') return urlFromSettings;
    if (urlFromWebsite && urlFromWebsite !== 'null') return urlFromWebsite;
    return userUrl;
  },
);

export const getWebsiteCountryISO = createSelector(getWebsite, (website) => website.ipCountryISO);

export const getAuthentication = (state) => {
  let auth = state.getIn(
    ['core', 'authentication'],
    Immutable.Map({
      inProgress: false,
      token: null,
      refreshToken: null,
      instanceId: null,
    }),
  );
  return auth.toJS();
};

export const isImpersonator = (state) => {
  const auth = getAuthentication(state);

  return auth.impersonateToken || false;
};

export const getFullWebsiteUrl = (state) => state.getIn(['core', 'website', 'url'], '');

export const isEmbeddedScriptEnabled = createSelector(
  [isWixWebsite, getWebsiteLastInstallDate],
  (isWixWebsite, lastInstallDate) => {
    if (!isWixWebsite || !WIX_EMBEDDED_SCRIPT_UNIX) {
      return false;
    }

    return lastInstallDate.unix() > WIX_EMBEDDED_SCRIPT_UNIX;
  },
);

export const hasSsrCodeInstalled = createSelector(
  [getWebsite, isEmbeddedScriptEnabled],
  (website, hasEmbeddedScript) => {
    if (hasEmbeddedScript) return true;

    const fFlags = website.frontendFlags;
    if (!fFlags) return false;

    return (fFlags.acceptedBetaSsr || fFlags.acceptedSsr) && fFlags.ssrCodeInstalled;
  },
);

export const hideSSrWarningSign = createSelector([getWebsite], (website) => {
  const fFlags = website.frontendFlags;
  return !!(fFlags && fFlags.dontShowSsrWarningSign);
});

export const hadRecordings = createSelector([getWebsite], (website) => {
  const fFlags = website.frontendFlags;
  return !!(fFlags && fFlags.hadRecordings);
});

export const wasInstalledAfterSsrV0 = createSelector(
  [getWebsite],
  (website) => (website.firstPremiumTs || website.installDate) >= SSR_V0_RELEASE_DATE_UNIX_TS,
);

export const getTrackingCodeStatusFlag = (state) =>
  state.getIn(['core', 'website', 'frontendFlags', 'trackingCodeInstalled'], null);

export const getSsrCodeInstalledFlag = (state) =>
  state.getIn(['core', 'website', 'frontendFlags', 'ssrCodeInstalled'], null);

export const isEventTrackingEnabled = createSelector([getWebsite], (website) => {
  return !!website?.frontendFlags?.startedEventTracking;
});

export const isWelcomeHeatmapsDismissed = createSelector([getWebsite], (website) => {
  return !!website?.frontendFlags?.welcomeHeatmaps;
});

export const getWebsiteOwnerId = (state) => state.getIn(['core', 'website', 'ownerId'], null);
