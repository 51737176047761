import { StopIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { Button, HorizontalDivider, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { useMemo } from 'react';
import { AppliedAddFilterButton } from './AppliedAddFilterButton';
import { RemoveAdditionalFiltersModal } from './RemoveAdditionalFiltersModal';
import './applied-filters.scss';
import { useGlobalFiltersConfig } from './ctx';
import { useFiltersContext } from './filters-context';
import { AppliedFilter } from './general-filter-inputs';

export const AppliedFilters = ({
  className,
  keepHorizontalDivider = true,
  size = 'large',
}: {
  className?: string;
  keepHorizontalDivider?: boolean;
  size?: 'small' | 'medium' | 'large';
}) => {
  const { appliedFilters, isExpanded, deleteAllAppliedFilters, hasAvailableFiltersLeft, templatesEnabled } =
    useFiltersContext();
  const translate = useTranslate();
  const { confirm } = useConfirmationDialogContext();
  const { SaveTemplateButton } = useGlobalFiltersConfig();
  const { canEditFilters } = usePermissionsProvider();

  const horizontalSeparatorLine = useMemo(() => <HorizontalDivider className='-translate-x-5' />, []);

  const shouldDisplayAddFilterBtn = hasAvailableFiltersLeft;

  const filterItems = useMemo(() => {
    const items: React.ReactNode[] = [];

    appliedFilters.forEach((filter, index) => {
      items.push(<AppliedFilter filter={filter} size={size} />);

      if (index !== appliedFilters.length - 1) {
        items.push(<CombinationalOperator size={size} />);
      }
    });

    return items;
  }, [appliedFilters, size]);

  if (appliedFilters.length === 0 || !isExpanded) {
    return null;
  }

  return (
    <div className='shrink-0 py-[1px] applied-filter-container'>
      {keepHorizontalDivider && horizontalSeparatorLine}
      <div
        className={classNames('flex flex-wrap gap-2 w-full', className, {
          'min-h-12': size === 'large',
        })}
      >
        {filterItems.map((item, index) => (
          <div key={index} className='contents'>
            {item}
          </div>
        ))}
        <div
          className={classNames('filter-buttons flex flex-row bg-gray-concrete p-3px w-auto gap-[1.5px]', {
            'min-h-12 rounded-15': size === 'large',
            'min-h-42px rounded-12': size === 'medium',
          })}
        >
          {shouldDisplayAddFilterBtn && <AppliedAddFilterButton size={size} />}
          {canEditFilters && templatesEnabled && SaveTemplateButton && (
            <SaveTemplateButton isAddFilterBtnVisible={shouldDisplayAddFilterBtn} />
          )}
          {canEditFilters && (
            <Button
              color='quinary'
              text={translate('button.removeAllFilters')}
              icon={() => <StopIcon className='shrink-0' color={'#EA2A0C'} />}
              className={classNames('grow md:min-w-min rounded-l-none remove-filters-btn', {
                'h-42px': size === 'large',
                'h-36px': size === 'medium',
              })}
              textClasses='text-12 text-red-pure'
              onClick={() =>
                confirm(() => {
                  deleteAllAppliedFilters();
                }, RemoveAdditionalFiltersModal)
              }
            />
          )}
        </div>
      </div>
      {keepHorizontalDivider && horizontalSeparatorLine}
    </div>
  );
};

export default AppliedFilters;

const CombinationalOperator = ({ size = 'large' }: { size?: 'small' | 'medium' | 'large' }) => {
  const translate = useTranslate();
  return (
    <Paragraph
      weight={fontWeights.medium}
      size={paragraphSizes.tiny}
      className={classNames('rounded-xl flex items-center justify-center px-3 bg-gray-concrete', {
        'h-12': size === 'large',
        'h-42px w-42px': size === 'small' || size === 'medium',
      })}
    >
      {/** Hardcoded it to AND for the time being, it's not clear yet how the OR operator should behave or if it's even needed, it will introduce more complexity */}
      {translate('all.filters.combinationalOperators.and')}
    </Paragraph>
  );
};
