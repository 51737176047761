import { defaultWebsiteId, tabNames, websiteFlagKeys } from '@va/constants';
import {
  CREATE_SSR_SETTINGS,
  GET_RECORDINGS_COUNT,
  GET_SSR_SETTINGS,
  GET_VIDEO_TRACKING_CODE_REQUEST,
  PROMPT_SSR_FEATURE,
  UPDATE_SESSION_EXTRAS,
  UPDATE_SSR_SETTINGS,
  createSsrSettingsFailed,
  createSsrSettingsSucceeded,
  getRecordingsCountFailed,
  getRecordingsCountSucceeded,
  getSsrSettingsFailed,
  getSsrSettingsSucceeded,
  getVideoTrackingCodeFailed,
  getVideoTrackingCodeSucceeded,
  promptSsrFeatureFailed,
  promptSsrFeatureSucceeded,
  requestWebsite,
  updateSessionExtrasFailed,
  updateSessionExtrasSucceeded,
  updateSsrSettingsFailed,
  updateSsrSettingsSucceeded,
  updateWebsiteFlags,
} from '@va/dashboard/actions/api';
import { setSessionExtras } from '@va/dashboard/actions/ui';
import Api from '@va/dashboard/api-client/index';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getActiveTab } from '@va/dashboard/selectors/ui';
import { getActiveWebsiteId } from '@va/standalone/shared/selectors';
import { isValidWebsiteId } from '@va/util/helpers';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([
    takeLatest(PROMPT_SSR_FEATURE, promptSsrFeatureSaga),
    takeLatest(GET_VIDEO_TRACKING_CODE_REQUEST, getVideoTrackingCodeSaga),
    takeLatest(GET_RECORDINGS_COUNT, getRecordingsCountSaga),
    takeLatest(CREATE_SSR_SETTINGS, createSsrSettingsSaga),
    takeLatest(GET_SSR_SETTINGS, getSsrSettingsSaga),
    takeLatest(UPDATE_SSR_SETTINGS, updateSsrSettingsSaga),
    takeLatest(UPDATE_SESSION_EXTRAS, updateSessionExtrasSaga),
  ]);
}

/** @deprecated */
function* promptSsrFeatureSaga(action) {
  try {
    if (action.value === false) {
      const activeTab = yield select(getActiveTab);
      const flagName = activeTab === tabNames.heatmaps ? websiteFlagKeys.ACCEPTED_HEATMAP : 'acceptedSsr';
      yield put(updateWebsiteFlags({ [flagName]: action.value })); // Flag used to identify websites that rejected ssr feature after beta release. True flag is set from api when createSsrSettings or activateHeatmaps
    }
    yield put(promptSsrFeatureSucceeded());
  } catch (error) {
    yield put(promptSsrFeatureFailed(error));
  }
}

function* getVideoTrackingCodeSaga() {
  try {
    const activeWebsiteId = yield select(getActiveWebsiteId);
    const coreWebsiteId = yield select(getInstanceId);
    const websiteId = activeWebsiteId !== defaultWebsiteId ? activeWebsiteId : coreWebsiteId;
    const response = yield call(Api.getVideoTrackingCode, websiteId);
    yield put(getVideoTrackingCodeSucceeded(response));
  } catch (error) {
    yield put(getVideoTrackingCodeFailed(error));
  }
}

function* getRecordingsCountSaga() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getRecordingsCount, websiteId);
    yield put(getRecordingsCountSucceeded(response));
  } catch (error) {
    yield put(getRecordingsCountFailed(error));
  }
}

function* createSsrSettingsSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.createSsrSettings, websiteId, action.data);
    yield put(createSsrSettingsSucceeded(response));
    yield put(getSsrSettingsSucceeded(response)); // because the create returns the new ssr settings, so it's like you get it again
    yield put(requestWebsite());
  } catch (error) {
    yield put(createSsrSettingsFailed(error));
  }
}

function* getSsrSettingsSaga() {
  try {
    const websiteId = yield select(getInstanceId);
    if (!isValidWebsiteId(websiteId)) throw new Error('No active website');
    const response = yield call(Api.getSsrSettings, websiteId);
    yield put(getSsrSettingsSucceeded(response));
  } catch (error) {
    yield put(getSsrSettingsFailed(error));
  }
}

function* updateSsrSettingsSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const ssrSettings = yield select(getApiRequest, 'getSsrSettings');
    const response = yield call(Api.updateSsrSettings, websiteId, ssrSettings.key, action.data);
    yield put(updateSsrSettingsSucceeded(response));
    yield put(getSsrSettingsSucceeded(response)); // because the update returns the new ssr settings, so it's like you get it again
  } catch (error) {
    yield put(updateSsrSettingsFailed(error));
  }
}

function* updateSessionExtrasSaga(action, newValue) {
  const sessionData = action.sessionData || newValue;

  try {
    const websiteId = yield select(getInstanceId);
    yield put(setSessionExtras(sessionData, action.id));
    const response = yield call(Api.updateSessionExtras, websiteId, action.id, sessionData);
    yield put(updateSessionExtrasSucceeded(response));
  } catch (error) {
    yield put(updateSessionExtrasFailed(error));
    yield put(setSessionExtras(null, action.id));
  }
}
